import React from 'react'
import MaterialTableCustom from '../../../components/MaterialTableCustom'
import { API_URL } from '../../../config'
import axios from 'axios';

const baseColumns = [
  { field: 'partNumber', title: 'PN' },
  {
    field: 'partType',
    title: 'Type',
  },
  {
    field: 'description',
    title: 'Description',
  },
  {
    field: 'availableStock',
    title: 'Total Qty',
  },
]

const makeColumnArr = (data) => {
  if (!data || !data.length) return []

  const columns = []

  // let count = 0;

  // const included = new Set();

  // for (const key in data[0]) {
  //   if (count >= 6 && count % 2 == 0) {
  //     let nz = true;
  //     for(let i = 1; i < data.length; i++) {
  //       if (data[i][count] !== 0) {
  //         nz = false;
  //         break;
  //       }
  //     }
  //     if (nz) {
  //       included.add(count);
  //     }
  //   }
  //   count += 1;
  // }

  // count = 0;

  const keys = new Set();
  for (const record of data) {
    for (const key of Object.keys(record)) {
      keys.add(key);
    }
  }
  for (const key of keys) {
    console.log(key);
    if (key.includes('Inv--')) {
      const label = key.substring(5)
      columns.push({
        field: key,
        title: label,
      })
    }
  }

  return [...baseColumns, ...columns]
}

const InventoryTable = ({ data }) => {
  if (!data) return undefined

  const title = 'Inventory Summary'

  const formattedColumns = makeColumnArr(data)

  const rowStyle = {
    fontSize: `13px`,
  }

  async function queryData(searchTerm) {
    const allDeliveriesAPIPath = `${API_URL}/api/inventory/v1/getInventoryData?limit=500&skip=0&QC=true&searchTerm=${searchTerm.trim()}`
    const res = await axios.get(allDeliveriesAPIPath)
    return res.data;
  }


  return (
    <>
      <MaterialTableCustom 
        title={title} 
        columns={formattedColumns} 
        data={data} 
        rowStyle={rowStyle} 
        queryData={queryData}
      />
    </>
  )
}

export default InventoryTable
